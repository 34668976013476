// colors
$white: #fff;
$black: #000024;
$blue: #089aff;
$orange: #fc593d;
$peach: #ffdfd9;
$red: #fc123d;
$yellow: #ffcc45;
$green: #2adb6a;
$forest: #05864a;
$mint: #d1ffe1;
$pink: #ff9ff4;
$blush: #ffe1fb;
$peach: #fceddc;
$violet: #e7e3fd;
$ultraviolet: #011efb;
$deepBlue: #00007d;

$gray: #7a7a8d;
$silver: #e4e4e6;

$sky: #cef2ff;
