.container {
	z-index: 100;
	position: sticky;
	top: 0;
	right: 0;
	left: 0;
	max-width: 100%;
	height: 88px;
	padding: 0 16px;
	box-shadow: 0 0 8px #0002;
	background-color: var(--des24-black);
	color: var(--des24-cream);

	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;

	overflow: hidden;
	overflow-x: scroll;

	& div > .item {
		font-size: 0.8em;
		padding: 8px 12px;
		cursor: pointer;
		border-radius: 999px;
		background-color: transparent;
		transition: background-color 250ms;

		&:hover {
			background-color: var(--des24-pink);
			color: var(--des24-black);
		}
		& > p {
			white-space: nowrap;
		}
	}

	& .navButtons {
		display: flex;
		flex-wrap: nowrap;
		gap: 10px;
	}

	& .linkButtons {
		display: grid;
		grid-auto-flow: column;
		column-gap: 20px;
		height: fit-content;
		width: fit-content;
	}

	@media screen and (min-width: 800px) {
		padding: 0 32px;

		& div > .item {
			font-size: 1em;
			padding: 12px 24px;
			cursor: pointer;
			border-radius: 999px;
			background-color: transparent;
			transition:
				background-color 250ms,
				color 250ms;

			&:hover {
				background-color: var(--des24-pink);
				color: var(--des24-black);
			}
		}
	}
}
