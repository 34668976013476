.container {
	position: relative;
	background-color: var(--des24-cream);
	color: var(--des24-black);

	padding: 5rem 2rem;

	@media screen and (min-width: 768px) {
		padding: 6rem 5rem;
	}

	.content {
		margin: 0 auto;
		max-width: 1280px;
	}

	.heading {
		position: relative;
		color: var(--des24-hot-pink);
		font-size: 3rem;
		font-weight: 700;
		margin-bottom: 2rem;

		@media screen and (min-width: 640px) {
			font-size: 5rem;
			margin-bottom: 4rem;
		}

		@media screen and (min-width: 1280px) {
			font-size: 6rem;
		}
	}

	.qa_container {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1.5rem;
		position: inherit;

		@media screen and (min-width: 768px) {
			gap: 3rem;
		}

		.qa_item {
			display: flex;
			flex-direction: column;
			cursor: pointer;
			border-radius: 12px;

			.question {
				display: flex;

				font-size: 1.5rem;
				cursor: pointer;

				@media screen and (min-width: 768px) {
					font-size: 2rem;
				}
			}

			.down_carat {
				height: 1rem;

				margin: auto 0;
				margin-right: 1rem;

				cursor: "pointer";

				@media screen and (min-width: 768px) {
					height: 2rem;
				}
			}
		}
	}
}

.answer_tag {
	position: relative;
	margin: 1.25rem 0 0;
	padding: 1rem;
	cursor: pointer;

	background-color: #ffe5e5;

	animation: fadeIn 0.5s ease-in;

	font-size: 1rem;
	line-height: 1.5rem;

	border-left: 2.5rem solid var(--des24-peach);
	border-radius: 0.25rem;
	box-shadow: 0px 4px 6px 2px #00000030;

	p {
		line-height: 1.5rem;

		@media screen and (min-width: 768px) {
			line-height: 2.5rem;
		}
	}

	@media screen and (min-width: 768px) {
		padding: 2rem;

		font-size: 1.25rem;
		line-height: 2.5rem;

		border-left: 5rem solid var(--des24-peach);
	}
}

.star_lines {
	position: absolute;
	top: 0;
	right: 0;
	max-width: 30%;
	pointer-events: none;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
