@import "app/styles/variables";

.fill.white {
	background-color: $white;
	color: $black;
}

.fill.black {
	background-color: $black;
	color: $white;
}

.fill.blue {
	background-color: $blue;
	color: $white;
}

.fill.pink {
	background-color: $pink;
	color: $black;
}

.fill.green {
	background-color: $forest;
	color: $white;
}

.fill.red {
	background-color: $red;
	color: $white;
}

.fill.sky {
	background-color: $sky;
}

.fill.gray {
	background-color: $silver;
}

.fill.dusk {
	background-color: $gray;
}

.color.white {
	color: $white;
}

.color.black {
	color: $black;
}

.color.gray {
	color: $gray;
}

.color.silver {
	color: $silver;
}

.color.blue {
	color: $blue;
}

.color.ultraviolet {
	color: $ultraviolet;
}

.color.sky {
	color: $sky;
}

.color.orange {
	color: $orange;
}

.color.yellow {
	color: $yellow;
}

.color.pink {
	color: $pink;
}

.color.red {
	color: $red;
}

.color.violet {
	color: $ultraviolet;
}

.color.green {
	color: $green;
}
