.container {
	display: grid;
	margin: 25px 0px;
	width: 100%;
	max-width: 210px;
	place-content: top;
	align-self: flex-start;

	@media screen and (max-width: 768px) {
		width: 50%;
		font-size: 0.85em;
	}

	& > .aspect {
		position: relative;
		width: 88%;
		padding-bottom: 88%;
		margin: 16px auto;

		& > img {
			width: 100%;
			height: 100%;
			position: absolute;
			object-fit: cover;
			object-position: center;
			border-radius: 12px;
			background-color: var(--silver);
		}
	}

	& > p {
		font-size: 1.5em;
		color: black;

		& > span {
			margin: 10px;
			font-size: 0.75em;
			color: grey;
		}
	}

	& > .socials {
		margin: 0 auto;
		width: min-content;
		display: grid;
		grid-auto-flow: column;
		column-gap: 8px;
	}
}
