.icon {
	margin-top: -32px;
	margin: -16px;
	margin-bottom: 0;
}

.resourcesBr {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: -10;
	pointer-events: none;
}

/* Adjust styles at a specific breakpoint */
@media (max-width: 1024px) {
	.resourcesBr {
		display: none;
	}
}
