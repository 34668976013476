.container {
	position: relative;
	background-color: var(--des24-black);
	background-image: url("../../assets/graphics/background/dark_splash.svg");
	background-repeat: repeat-y;
	background-size: 100% auto;

	padding: 5rem 2rem;

	.content {
		margin: 0 auto;
		max-width: 1280px;
	}

	.heading {
		color: var(--des24-hot-pink);
		font-size: 3rem;
		font-weight: 700;
		margin-bottom: 4rem;

		@media screen and (min-width: 640px) {
			font-size: 5rem;
		}

		@media screen and (min-width: 1280px) {
			font-size: 6rem;
		}
	}

	.rules {
		position: relative;
		height: 100%;
		min-height: 100%;

		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 1.5rem;

		@media screen and (min-width: 768px) {
			grid-template-columns: repeat(2, 1fr);
			gap: 3rem;
		}

		@media screen and (min-width: 1280px) {
			grid-template-columns: repeat(3, 1fr);
			gap: 5rem;
		}

		.rule {
			width: 100%;
			aspect-ratio: 1;
			display: grid;
			place-content: center;
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			max-width: 350px;
			margin: auto;

			.text {
				margin: 0 auto;
				max-width: calc(80% - 20px);
				color: black;
				text-align: center;

				font-size: 1rem;

				@media screen and (min-width: 1280px) {
					font-size: 1rem;
				}

				.number {
					font-size: 1rem;
					text-align: center;

					@media screen and (min-width: 1024px) {
						font-size: 2rem;
					}
				}
			}

			.bg {
				height: 100%;

				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	@media screen and (min-width: 768px) {
		padding: 6rem 5rem;
	}
}

.hearts {
	position: absolute;
	width: 25%;
	pointer-events: none;

	top: 2%;
	right: 2%;

	@media screen and (min-width: 768px) {
		width: 15%;
	}
}
