@import "app/styles/variables";

.spaceChildren > * {
	margin-bottom: 32px;
}

.spaceChildrenSmall > * {
	margin-bottom: 16px;
}

.spaceChildrenLarge > * {
	margin-bottom: 64px;
}

.spaceChildren > *:last-child {
	margin-bottom: 0;
}

.spaceChildrenSmall > *:last-child {
	margin-bottom: 0;
}

.spaceChildrenLarge > *:last-child {
	margin-bottom: 0;
}

.maxWidth {
	width: 100%;
}

.space {
	display: inline-block;
}

break {
	display: block;
}

.section {
	display: block;
	padding: 128px 64px;
}

.flatBot {
	padding-bottom: 0;
}

.flatTop {
	padding-top: 0;
}

#root > .section {
	overflow: hidden;
}

.section.short {
	padding: 64px 64px;
}

.section.bare {
	padding: 0px 64px;
}

.section.shortTop {
	padding-top: 32px;
}

.transition {
	height: 64px;
	margin-top: -64px;
	background: linear-gradient(0, #00001008, #0010);
}

.section .wrapper > * {
	margin-bottom: 32px;
}

.section .wrapper > *:last-child {
	margin-bottom: 0;
}

.relative {
	position: relative;
}

.pageFull {
	min-height: 100vh;
}

.page,
.widePage {
	min-height: calc(100vh - 88px);
}

.page.hint,
.widePage.hint {
	min-height: calc(100vh - 176px);
}

.background {
	z-index: 0;
	border-radius: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.flex {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.flex.row {
	flex-direction: row;
}

.flex.wideRow {
	flex-direction: row;
}

.flex.left {
	align-items: flex-start;
}

.flex.right {
	align-items: flex-end;
}

.flex.top {
	justify-content: flex-start;
}

.flex.bottom {
	justify-content: flex-end;
}

.flex.wrap {
	flex-wrap: wrap;
}

.flex.spaceBetween {
	justify-content: space-between;
}

.split2 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 64px;
}

.split12 {
	display: grid;
	grid-template-columns: 1fr 2fr;
	gap: 64px;
}

.split3,
.split32 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 32px;
}

.splitEventCard {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 32px;
}

.split4 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 16px;
}

.split2.wideFlip > div:nth-child(1) {
	order: 2;
}

.split2.wideFlip > div:nth-child(2) {
	order: 1;
}

.wrapper {
	width: 100%;
	max-width: 1280px;
	margin-left: auto;
	margin-right: auto;
}

.center,
.center > .wrapper {
	display: flex;
	text-align: center;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.center.row {
	flex-direction: row;
}

.middle {
	margin-left: auto;
	margin-right: auto;
}

.slim {
	max-width: 1024px;
}

.narrow {
	max-width: 768px;
}

.tiny {
	max-width: 512px;
}

.nano {
	max-width: 384px;
}

@media only screen and (max-width: 1024px) {
	break.collapse {
		display: none;
	}

	.split32 {
		grid-template-columns: 1fr 1fr;
	}

	.split2,
	.split12 {
		grid-template-columns: 1fr;
		gap: 32px;
	}

	.split2 .first {
		grid-row: 0/1;
	}

	.split4 {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 16px;
	}
}

@media only screen and (max-width: 768px) {
	.wideOnly {
		display: none !important;
	}

	.flex.wideRow {
		flex-direction: column;
	}

	.split3,
	.split32 {
		grid-template-columns: 1fr;
	}

	.split2.wideFlip > div:nth-child(1) {
		order: 1;
	}

	.split2.wideFlip > div:nth-child(2) {
		order: 2;
	}

	break {
		display: none;
	}

	.section.flex {
		display: flex;
	}

	.text {
		font-size: 16px;
	}

	.text.L {
		font-size: 20px;
	}

	.text.XL {
		font-size: 32px;
	}

	.text.XXL {
		font-size: 48px;
	}

	.text.XXXL {
		font-size: 44px;
	}

	.widePage {
		min-height: unset;
	}

	.section {
		display: block;
		padding: 128px 32px;
	}

	.section.short {
		padding: 64px 32px;
	}

	.section.bare {
		padding: 0px 32px;
	}

	.section,
	.section.short,
	.section.bare {
		display: block;
		padding-left: 32px;
		padding-right: 32px;
	}

	.section.center,
	.section.flex {
		display: flex;
	}
}

.section.bareTop {
	padding-top: 0;
}

.section.bareBot {
	padding-bottom: 0;
}

.section.path {
	background: linear-gradient(90deg, $silver -20%, $white 50%);
}

.section.path a {
	padding-top: 4px;
	padding-bottom: 4px;
}

.section.path:hover {
	background: linear-gradient(90deg, $sky, $white);
}

.section.path .flex .div {
	margin: 0 8px;
}

.section.path .flex .text {
	color: $gray;
}

.section.path:hover .flex .text {
	color: $blue;
	opacity: 1;
}

.section.path .flex a:hover .text {
	text-decoration: underline;
}
