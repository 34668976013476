.container {
	.hero {
		margin-bottom: 0;
		z-index: -1;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background: url("https://designatuci.com/static/photo/hero.png") bottom /
			calc(768px + 38vw) no-repeat;
	}
}
