@import "app/styles/variables";

#navSpacer {
	height: 88px;
	display: block;
}

#nav {
	position: relative;
	padding: 0 48px;
	z-index: 1000;
	background: white;

	& .logo {
		min-width: 190px;
	}

	& .wrapper {
		display: grid;
		max-width: calc(1280px + 32px);
		grid-template-columns: 1fr 1fr 1fr;
		transition: height 0.2s cubic-bezier(0.55, 0.01, 0.38, 0.99);
	}

	& .mobile {
		display: none;
		grid-template-rows: 88px 1fr;
	}

	& .wide {
		display: grid;
		height: 88px;
	}

	& .item.social {
		padding-right: 8px;
		padding-left: 8px;

		&:hover {
			animation: itemBounce 0.333s forwards;
		}
	}

	@media screen and (max-width: 800px) {
		padding: 0 16px;
		box-shadow: 0 0px 128px 64px #6670;

		& > .wrapper {
			grid-template-columns: 1fr 64px;
		}

		& .mobile {
			display: grid;
		}

		& .wide {
			display: none;
		}

		& .item {
			padding: 8px 0;

			&.button {
				padding: 16px 0;
			}
		}
	}
}

#nav.clear {
	background: none;
}

#nav .group.left {
	justify-content: flex-start;
}

#nav .group.right {
	justify-content: flex-end;
}

#nav .group a {
	display: inline-block;
	padding: 16px;
	color: $gray;
}

#nav.clear .group a {
	color: $white;
}

#nav .group a:hover {
	color: $black;
}

#nav.clear .group a:hover {
	color: $white;
}

@media only screen and (min-width: 800px) {
	#nav.shrink > .wrapper {
		height: 66px;
	}
}

#nav.shrink {
	background: #fff;
	box-shadow: 0 0 1px 1px $silver;
}

@keyframes itemBounce {
	0% {
		transform: translateY(0px);
		animation-timing-function: cubic-bezier(0, 0.7, 0.51, 0.99);
	}

	25% {
		transform: translateY(-4px);
		animation-timing-function: cubic-bezier(0.55, 0.01, 0.51, 0.99);
	}

	65% {
		transform: translateY(1px);
		animation-timing-function: cubic-bezier(0.55, 0.01, 0.51, 0.99);
	}

	100% {
		transform: none;
		animation-timing-function: cubic-bezier(0, 0.59, 0.51, 0.99);
	}
}

#nav .mobile .links {
	grid-column: 1/3;
}

#nav #navToggle {
	transition: background 0.2s cubic-bezier(0.6, 0.01, 0.83, 0.67);
	background: $white;
	border-radius: 32px;
}

#nav #navToggle:active {
	transition: background 0s;
	background: $silver;
}

#nav #navToggle .icon {
	background-size: cover;
	background-position: bottom;
}

#nav .links {
	display: none;
	padding-bottom: 16px;
}

#nav .links .item {
	opacity: 0;
}

#nav[mobile-expand="true"] .links {
	transform-origin: bottom;
	animation: links-in 1.6s cubic-bezier(0.34, 1.54, 0.36, 1) forwards;
}

#nav[mobile-expand="true"] .links .item {
	animation: link-in 0.4s cubic-bezier(0.33, 0.53, 0.18, 1) forwards;
}

#nav[mobile-expand] .links .item:nth-child(1) {
	animation-delay: 0s;
}

#nav[mobile-expand] .links .item:nth-child(2) {
	animation-delay: 0.05s;
}

#nav[mobile-expand] .links .item:nth-child(3) {
	animation-delay: 0.1s;
}

#nav[mobile-expand] .links .item:nth-child(4) {
	animation-delay: 0.15s;
}

#nav[mobile-expand] .links .item:nth-child(5) {
	animation-delay: 0.2s;
}

@keyframes links-in {
	0% {
		transform: perspective(256px) rotateX(-40deg);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes link-in {
	0% {
		transform: translateX(-64px);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

#nav[mobile-expand="true"] .links {
	display: block;
}

#nav[mobile-expand="true"] {
	transition: box-shadow 0.8s cubic-bezier(0.02, 0.69, 0.12, 1);
	box-shadow: 0 128px 128px 128px #334b;
}

#nav[mobile-expand="true"] .brand {
	color: black;
}

#nav[mobile-expand="true"] .brand .icon {
	background-position: top;
}

#nav[mobile-expand="true"] #navToggle .icon {
	background-position: top;
}
