.perks {
	text-align: center;
	background-color: #000024;
	color: white;

	.list {
		display: grid;
		gap: 20px;
		place-content: center;

		.item {
			text-align: left;
			max-width: 800px;

			display: grid;
			grid-template-columns: auto 1fr;
			grid-template-rows: auto auto;
			row-gap: 8px;
			column-gap: 20px;

			padding: 20px;
			border: 2px solid var(--gray);
			border-radius: 8px;

			img {
				height: 60px;
				grid-row: 1 / 3;
				grid-column: 1 / 2;
			}
		}
	}
}
