.join {
	height: 60vh;
	display: grid;
	place-content: center;
	max-width: 800px;
	margin: 0 auto;

	.title {
		text-align: center;
		font-weight: bold;
	}

	.description {
		text-align: center;
		margin-top: 20px;
	}

	.button {
		margin: 40px auto;
	}
}
