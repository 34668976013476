.container {
	& > .hero {
		height: 100vh;
		width: 100%;
		background-color: black;
		display: grid;
		overflow: hidden;
		place-content: center;

		& > img {
			width: 100%;
			max-width: 100%;
			max-height: 100%;
			transform: rotate(0deg) scale(0);
			object-fit: contain;
			animation: rotate linear 10s infinite forwards;
			will-change: transform;
		}

		& > .title {
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			display: grid;
			place-content: center;
			color: var(--yellow);
			z-index: 100;
			text-align: center;
			padding-top: 140px;

			& .icon {
				margin: 0 auto;
			}
		}
	}

	& > .about {
		padding: 10px;
		background-color: #e4e4e6;
		font-size: 1em;

		& h1 {
			font-size: 2em;
			font-weight: bold;
		}

		& p {
			font-size: 1.2em;
			line-height: 1.5em;
		}

		@media screen and (min-width: 1100px) {
			padding-bottom: 80px;

			& h1 {
				font-size: 3em;
			}

			& p {
				font-size: 1.3em;
				line-height: 1.4em;
			}
		}
	}

	& > .prizes {
		background-color: white;
	}

	& > .people {
		color: white;
		background-color: black;
		padding: 0px;

		& h1 {
			font-size: 2em;
			font-weight: bold;
		}

		@media screen and (min-width: 700px) {
			padding: 20px;

			& h1 {
				font-size: 3em;
			}
		}

		@media screen and (min-width: 900px) {
			padding: 40px;

			& h1 {
				font-size: 3em;
			}
		}

		& .section {
			display: grid;
			column-gap: 20px;
			row-gap: 20px;
			grid-template-columns: repeat(2, 1fr);
			padding: 20px 0;

			& > h2 {
				color: var(--yellow);
				grid-column: 1 / -1;
				font-size: 2em;
				font-weight: bold;

				@media screen and (min-width: 1000px) {
					font-size: 3em;
				}
			}

			@media screen and (min-width: 1000px) {
				grid-template-columns: repeat(3, 1fr);
				gap: 20px;
			}

			@media screen and (min-width: 1200px) {
				grid-template-columns: repeat(4, 1fr);
				gap: 40px;
			}
		}
	}

	& .sponsors {
		background-color: var(--silver);

		& .logos {
			padding-top: 60px;
			display: grid;
			column-gap: 80px;
			row-gap: 80px;
			width: 100%;
			grid-template-columns: repeat(1, 1fr);
			place-content: center;

			@media screen and (min-width: 800px) {
				max-height: none;
				grid-template-columns: repeat(3, 1fr);
			}

			& > div {
				display: grid;
				place-content: center;
			}
			& img {
				max-height: 200px;
				width: 100%;
				object-fit: contain;
			}
		}
	}
}
