.intro {
	background-color: #000024;
	text-align: center;

	.wrapper {
		max-width: 800px;
	}

	.description {
		line-height: 1.7em;
	}

	.title {
		font-weight: bold;
	}
}
