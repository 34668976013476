@import "app/styles/variables";

.container {
	display: inline-block;
	line-height: 1.12;
	font-size: 18px;
}

.container > .container,
.container s {
	display: inline;
	vertical-align: baseline;
}

.block-paragraph > .container {
	display: inline-block;
}

.container s {
	text-decoration: none;
	font-size: inherit;
	font-weight: inherit;
}

// colors
.container {
	&.white {
		color: $white;
	}

	&.black {
		color: $black;
	}

	&.gray {
		color: $gray;
	}

	&.grey {
		color: $gray;
	}

	&.silver {
		color: $silver;
	}

	&.blue {
		color: $blue;
	}

	&.sky {
		color: $sky;
	}

	&.orange {
		color: $orange;
	}

	&.yellow {
		color: $yellow;
	}

	&.pink {
		color: $pink;
	}

	&.red {
		color: $red;
	}

	&.violet {
		color: $ultraviolet;
	}

	&.green {
		color: $green;
	}

	// size
	&.S {
		font-size: 14px;
	}

	&.L {
		font-size: 24px;
	}

	&.XL {
		font-size: 36px;
	}

	&.XXL {
		font-size: 56px;
	}

	&.XXXL {
		font-size: 64px;
	}
	@media screen and (max-width: 768px) {
		font-size: 16px;

		&.S {
			font-size: 14px;
		}

		&.L {
			font-size: 20px;
		}

		&.XL {
			font-size: 32px;
		}

		&.XXL {
			font-size: 48px;
		}

		&.XXXL {
			font-size: 44px;
		}
	}
}

.container .link {
	text-decoration: underline;
}

.container .link:hover {
	color: black;
}

.containerAlignLeft .container,
.containerAlignLeft {
	text-align: left;
}

.container.indent {
	text-indent: 2em;
}

.container {
	&.pagelink::after {
		z-index: 1;
		content: "";
		display: inline-block;
		background: url("https://designatuci.com/static/icon/link-arrow.svg") center
			no-repeat;
		background-size: contain;
		width: 18px;
		height: 18px;
		vertical-align: middle;
		transform: translate(0px, -2px);
		margin-left: 6px;
		transition: transform 0.4s cubic-bezier(0.47, 0.4, 0, 1);
	}
	&.pagelink.white::after {
		background: url("https://designatuci.com/static/icon/link-arrow-white.svg")
			center no-repeat;
	}
	&.pagelink.L::after {
		width: 24px;
		height: 24px;
		margin-left: 10px;
		background-size: contain;
	}

	&.pagelink:hover::after {
		transform: translate(2px, -2px) scale(1.62);
		transition-timing-function: cubic-bezier(0.42, 1.52, 0.56, 1);
	}
}
